<template>
  <figure>
    <RokkaImage
      v-if="imageStyle && rokkaImageHash"
      :config="imageStyle"
      :file-name="rokkaFileName"
      :alt="image?.alt"
      :title="image?.title"
      :img-class="imgClass"
      :loading="loading"
      :preload="preload"
      :hash="rokkaImageHash"
    />
    <img v-else :src="imgSrc" :loading="loading" :class="imgClass" />
  </figure>
</template>

<script lang="ts" setup>
import type { DefineImageStyleConfig } from '#rokka/types'
import type { MediaImageFragment } from '#graphql-operations'

type baseProps = {
  imgClass?: string
  eager?: boolean
}

type rokkaProps = {
  imageStyle?: DefineImageStyleConfig
  image?: MediaImageFragment['image']
  rokkaHash?: string // provide either 'image' or 'rokkaHash'

  /**
   * Preload the image. When set, the images are reploaded using a <link> tag.
   */
  preload?: boolean
}

type imgProps = {
  url?: MediaImageFragment['url'] | string
}

const props = defineProps<baseProps & rokkaProps & imgProps>()

defineOptions({
  name: 'MediaImage',
})

const actualUrl = computed(() => {
  if (props.url) {
    if (typeof props.url === 'string') {
      return props.url
    } else {
      return props.url.path
    }
  }

  return undefined
})

const loading = computed(() => (props.eager ? 'eager' : 'lazy'))
const imgSrc = computed(() => {
  if (rokkaImageHash.value) {
    return buildRokkaUrl(
      'dynamic',
      '',
      rokkaImageHash.value,
      rokkaFileName.value,
    )
  }

  return actualUrl.value
})

const rokkaImageHash = computed(() => {
  let rokkaImageHash = props.image?.file?.rokka?.hash || props.rokkaHash

  if (!rokkaImageHash && actualUrl.value) {
    const url = new URL(actualUrl.value)

    if (url.hostname === 'image.oris.ch') {
      // this url is whitelisted at rokka to proxy urls from.
      rokkaImageHash = `-${actualUrl.value}-`
    }
  }

  return rokkaImageHash
})

const rokkaFileName = computed(() => {
  let rokkaFileName = props.image?.file?.label

  if (!rokkaFileName && actualUrl.value) {
    rokkaFileName = actualUrl.value.split('/').pop()
  }

  return rokkaFileName
})
</script>
